import React from "react";
import Hero from "../../components/Hero";
import Gallery from "../../components/Gallery";
import Title from "../../components/Title";
import CareerImage from "../../components/CareerImage";
import Jobs from "../../components/Jobs";

import { ScrollingProvider } from "react-scroll-section";
import {
    Container,
    MainTitleWrapper,
    TitleContainer,
    TitleWrapper,
    SubTitleWrapper,
    Grid,
    GridText,
    Col,
    ColTitle,
    AllJobsSection,
} from "./styled";

// Create Grid (title + text)
const createGrid = (dataGrid, contentRef) => dataGrid && dataGrid.map((data, index) => {
    let { title, text } = data;
    return (
        <Col key={`col-${index}`}>
            <ColTitle>
                <Title
                    key={`col-title-${index}`}
                    title={title}
                    ref={contentRef}
                    element={SubTitleWrapper}
                />
            </ColTitle>
            <GridText weight="regular">{text}</GridText>
        </Col>
    )
})

// Careers
const Careers = ({ contentRef, jobs, dataHero, dataTitle, dataGrid, gallery, mainImage, emptyData }) => <Container>
    <ScrollingProvider ref={contentRef} key="careers">
        <MainTitleWrapper>
            <Hero
                ref={contentRef}
                title={dataHero.title}
                text={dataHero.text}
                actions={dataHero.actions}
            />
        </MainTitleWrapper>
        <Gallery gallery={gallery} />
        <TitleContainer>
            <Title
                key={'title'}
                title={dataTitle}
                ref={contentRef}
                element={TitleWrapper}
                inline={true}
                justify={"center"}
            />
        </TitleContainer>
        <Grid>{createGrid(dataGrid, contentRef)}</Grid>
        {mainImage && <CareerImage 
            image={mainImage.src}
            title={mainImage.title}
        />}
        <AllJobsSection key={'sectionJobs'} id={'jobs'}>
            <TitleContainer>
                <Title
                    key={'title-jobs'}
                    title={[
                        {
                            label: 'Open Positions at Meta Craft',
                        }
                    ]}
                    ref={contentRef}
                    element={TitleWrapper}
                    inline={true}
                    justify={"center"}
                />
            </TitleContainer>
            <Jobs jobs={jobs} emptyData={emptyData} />
        </AllJobsSection>
    </ScrollingProvider>
</Container>

export default Careers