import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Layout, media } from "@theme";
import Image from "../UI/Image";
import { Body1 } from "../UI/Typography";

// Container
export const Container = styled.div`
    margin-top: 80px;
    margin-bottom: 80px;

    ${media.small} {
        margin-top: 40px;
        margin-bottom: 40px;
    }
`;

// Wrapper
export const Wrapper = styled(Layout.Wrapper)`
    position: relative;
    height: 100%;
    text-align: center;
    padding: 0 !important;
`;

// Border (frames)
const frameStyle = css`
    &::before {
        content: "";
        width: 49px;
        height: 54px;
        position: absolute;
        top: 40px;
        left: 30px;
        z-index: 2;
        background: url(/quote/hand-down.svg);
        background-size: cover;
    }

    &::after {
        content: "";
        width: 49px;
        height: 54px;
        position: absolute;
        bottom: 40px;
        right: 30px;
        z-index: 2;
        background: url(/quote/hand-up.svg);
        background-size: cover;
    }

    ${media.small} {
        &::before {
            width: 25px;
            height: 27px;
            top: 15px;
            left: 15px;
        }
        &::after {
            width: 25px;
            height: 27px;
            bottom: 15px;
            right: 15px;
        }
    }
`;

export const ImageWrapper = styled.div`
    ${frameStyle}
`;


// Image
export const MainImage = styled(Image)`
    border-radius: 10px;
    
    ${media.small} {
        border-radius: 5px;
    }
`;

// Title
export const Title = styled(Body1)`
    position: absolute;
    color: ${props => props.color ? props.color : 'white'};
    width: 80%;
    top: 50%;
    margin-left: 10%;
    margin-right: 10%;
    transform: translateY(-50%);
`;