import React from "react";
import Image from "../../components/UI/Image";
import {
    Container,
    FlexContainer,
    FlexItem,
    FlexItemMiddle,
    FlexCenter,
    FlexCenterItem,
    FlexCenterRowUp,
    FlexCenterRowDown,
    FlexCenterRowItem,
    FlexAnotherItem,
} from "./styled";

// Gallery Component
const Gallery = ({ gallery }) => <Container>
    <FlexContainer>
        <FlexItem style={{width: '20%'}}>
            <FlexAnotherItem style={{boxSizing: 'border-box'}}>
                <div style={{maxWidth: '255px'}}>
                    {gallery && gallery[0] && <Image
                        image={gallery[0]}
                        alt="Image 1"
                    />}
                </div>
            </FlexAnotherItem>
        </FlexItem>
        <FlexItemMiddle style={{width: '60%'}}>
            <FlexCenter>
                <FlexCenterItem>
                    <FlexCenterRowUp>
                        <FlexCenterRowItem style={{maxWidth: '254px'}}>
                            {gallery && gallery[1] && <Image
                                image={gallery[1]}
                                alt="Image 2"
                            />}
                        </FlexCenterRowItem>
                        <FlexCenterRowItem style={{maxWidth: '350px'}}>
                            {gallery && gallery[2] && <Image
                                image={gallery[2]}
                                alt="Image 3"
                            />}
                        </FlexCenterRowItem>
                    </FlexCenterRowUp>
                </FlexCenterItem>
                <FlexCenterRowDown>
                    <FlexCenterRowItem style={{maxWidth: '349px'}}>
                        {gallery && gallery[3] && <Image
                            image={gallery[3]}
                            alt="Image 4"
                        />}
                    </FlexCenterRowItem>
                    <FlexCenterRowItem style={{maxWidth: '254px'}}>
                        {gallery && gallery[4] && <Image
                            image={gallery[4]}
                            alt="Image 5"
                        />}
                    </FlexCenterRowItem>
                </FlexCenterRowDown>
            </FlexCenter>
        </FlexItemMiddle>
        <FlexItem style={{width: '20%'}}>
            <FlexAnotherItem style={{boxSizing: 'border-box'}}>
                <div style={{maxWidth: '160px'}}>
                    {gallery && gallery[5] && <Image
                        image={gallery[5]}
                        alt="Image 6"
                    />}
                </div>
            </FlexAnotherItem>
        </FlexItem>
    </FlexContainer>
</Container>

export default Gallery