import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Measurements, media } from "@theme";

export const Container = styled.div`
    margin-right: auto;
    margin-left: auto;
    max-width: ${Measurements.content.width};
    height: 100%;
`;

// Item style
const ItemStyle = css`
    width: 100%;
    order: 0;
    flex: 0 1 auto;
`;

export const FlexContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: stretch;
`;

export const FlexItem = styled.div`
    ${ItemStyle}
    align-self: center;
    padding: 5px;
    ${media.small} {
        padding: 0;
    }
`;

export const FlexItemMiddle = styled.div`
    ${ItemStyle}
    align-self: auto;
    padding: 10px;
    ${media.small} {
        padding: 5px;
    }
`;

export const FlexCenter = styled.div`
    /*width: 100%;*/
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
`;

export const FlexCenterItem = styled.div`
    ${ItemStyle}
    align-self: center;
`;

// Center Style
const FlexCenterRowStyle = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
`;

export const FlexCenterRowUp = styled.div`
    ${FlexCenterRowStyle}
    align-items: flex-end;
`;

export const FlexCenterRowDown = styled.div`
    ${FlexCenterRowStyle}
    align-items: flex-start;
`;

export const FlexCenterRowItem = styled.div`
    ${ItemStyle}
    align-self: auto;
    padding: 10px;
    ${media.small} {
        padding: 5px;
    }
`;

export const FlexAnotherItem = styled.div`
    width: 100%;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    ${media.small} {
        padding: 5px;
    }
`;
