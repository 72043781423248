import React from "react";
import PropTypes from "prop-types";
import {
    Container,
    Wrapper,
    ImageWrapper,
    MainImage,
    Title,
} from "./styled";

// Career Image Component
const CareerImage = ({ image, title }) =>
    <Container>
        <Wrapper>
            {image && <ImageWrapper>
                <MainImage image={image} alt={title.label} />
            </ImageWrapper>}
            {title && <Title weight="bold" color={title.color}>{title.label}</Title>}
        </Wrapper>
    </Container>

CareerImage.propTypes = {
    title: PropTypes.shape({
        label: PropTypes.string,
        color: PropTypes.string,
    })
}

CareerImage.defaultProps = {
    title: PropTypes.shape({
        label: "",
    })
}

export default CareerImage
