import React, { useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Careers from "../containers/careers";
import get from "lodash/get";

const Page = ({ data }) => {
    const contentRef = useRef();

    // Get SEO
    const dataSEO = get(data, 'careersYaml.seo', undefined);
    // Get Jobs
    const jobs = get(data, 'allMarkdownRemark.edges', []).map(({ node }) => node);
    const empty = get(data, 'careersYaml.empty', []);
    // Get Hero info
    const dataHero = get(data, 'careersYaml.hero', undefined);
    // Get Title info
    const dataTitle = get(data, 'careersYaml.title', undefined);
    // Get Grid info
    const dataGrid = get(data, 'careersYaml.grid', undefined);
    // Get Gallery
    const gallery = get(data, 'careersYaml.images', undefined);
    const mainImage = get(data, 'careersYaml.mainImage', undefined);

    return (
        <Layout ref={contentRef}>
            <SEO
                title={dataSEO.title || "Careers"}
                description={dataSEO.description || ""}
            />
            <Careers
                contentRef={contentRef}
                jobs={jobs}
                dataHero={dataHero}
                dataTitle={dataTitle}
                dataGrid={dataGrid}
                gallery={gallery}
                mainImage={mainImage}
                emptyData={empty}
            />
        </Layout>
    )
}

export default Page;

export const query = graphql`
    query CareersQuery {
        careersYaml {
            seo {
                title
                description
            }
            hero {
                title {
                    label
                    color
                }
                text {
                    label
                    color
                    weight
                }
                actions {
                    label
                    link
                }
            }
            title {
                label
                styled {
                    backgroundColor
                    color
                }
            }
            grid {
                title {
                    label
                    styled {
                        backgroundColor
                        color
                        margin
                    }
                }
                text
            }
            images {
                childImageSharp {
                    gatsbyImageData(width: 360, placeholder: NONE)                   
                }
            }
            mainImage {
                src {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE)                   
                    }
                }
                title {
                    label
                    color
                }
            }
            empty {
                text
                button {
                    link
                    label
                }
            }
        }

        allMarkdownRemark(
            filter: {fields: {group: {eq: "jobs"}}},
            sort: {order: DESC, fields: frontmatter___publication_date}
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        tagline
                        tags
                        publication_date
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`;
