import React from "react";
import styled from "@emotion/styled";
import { H2, Body1, Quote } from "../../components/UI/Typography";
import { Layout, media } from "@theme";

import { Section } from "react-scroll-section";

export const Container = styled(Layout.Wrapper)`
    ${media.small} {
        padding-top: 30px;
    }
`;

export const MainTitleWrapper = styled(Layout.MainTitleWrapper)``;

export const TitleContainer = styled.div`
    margin: 8rem auto;
    ${media.small} {
        margin: 5rem auto;
        letter-spacing: -0.52px;
    }
`;
export const TitleWrapper = ({ children }) => <H2 weight="bold" style={{ textAlign: 'center' }}>{children}</H2>;

const Subtitle = styled(Body1)`
    margin: 10px;
    ${media.small} {
        font-weight: 500;
        font-size: 1.125em;
    }
`;

export const SubTitleWrapper = ({ children }) => <Subtitle weight="medium">{children}</Subtitle>;

export const Grid = styled.div`
    display: flex;
    margin: 0 0 20px 0;
    justify-content: space-between;
    flex-wrap: wrap;

    ${media.small} {
        flex-direction: column;
        text-align: center;

        & {
            display: block;
        }
    }
`;

export const GridText = styled(Quote)`
    margin-top: 3rem;
    padding-left: 10px;
    padding-right: 25px;

    ${media.tablet} {
        margin-top: 0;
    }

    ${media.small} {
        padding: 0;
        margin: 0 auto;
        margin-bottom: 1.5rem;
        max-width: 260px;
        line-height: 24px;
    }
`;

export const Col = styled.div`
    width: 24%;

    ${media.smallMedium} {
        margin-top: 2rem;
        flex: 1 0 48%;
    }
    ${media.small} {
        & {
            width: 100%;
            margin: 0 0 20px 0;
        }
    }
`;

export const ColTitle = styled.div`
    height: 115px;
    overflow: hidden;
    ${media.small} {
        text-align: center;
        height: auto;

        & div {
            justify-content: center;
        }
    }
`;

export const AllJobsSection = styled(Section)`
    padding-bottom: 10rem;
`;